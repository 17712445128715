import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import I18n from '../I18n';

export default function ResponsiveDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // function handleClickOpen() {
  //   setOpen(true);
  // }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
          <DialogContentText>
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={handleClose} color="primary">
                      Disagree
                    </Button>*/}
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            {I18n.getTranslation(window.location, 'GLOBAL_CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}