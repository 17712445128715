import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NoteIcon from '@material-ui/icons/Note';
import { NavLink } from '../lib';
// import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import I18n from '../I18n';
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title:{
  	color: "#3c4043",
  	marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  link: {
  	textDecoration: 'none',
  },
  sidebar: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(1)
    }
  },
  listPadding: {
  	padding: 0
  },
  divider: {
  	marginTop: theme.spacing(3),
  	marginBottom: theme.spacing(3),
  }
}));

const Sidebar = (props) => {

  const classes = useStyles();

	return (
	<div className={classes.sidebar}>
		<Typography component="h3" variant="h6" className={classes.title}>
		  {I18n.getTranslation(window.location, 'HELP')}
		</Typography>
		<div className={classes.demo}>
			<List dense disablePadding>
				<ListItem className={classes.listPadding}>
					<ListItemIcon>
						<NoteIcon color="primary" />
					</ListItemIcon>
          <NavLink to="/stations" className={classes.link} >
            <ListItemText
              primary={I18n.getTranslation(window.location, 'LOCATION_STATIONS')}
              classes={{ primary: classes.link }}
            />
          </NavLink>
				</ListItem>
        <ListItem className={classes.listPadding}>
          <ListItemIcon>
            <NoteIcon color="primary" />
          </ListItemIcon>
          <NavLink to="/airports" className={classes.link} >
            <ListItemText
              primary={I18n.getTranslation(window.location, 'LOCATION_AIRPORTS')}
              classes={{ primary: classes.link }}
            />
          </NavLink>          
        </ListItem>			
			</List>
		</div>
    <CookieConsent
      // debug={true}
      buttonText={I18n.getTranslation(window.location, 'COOKIE_NOTICE_BUTTON')}
      style={{ background: "#e5e5e5", 
          color: "#444"
          }}
      cookieName="gdpr"
      buttonStyle={{ 
        background: "#b71c1c", 
        color: "#FFF", 
        padding: "10px",
        fontWeight: "500",
        fontSize: "0.875rem",
        letterSpacing: "0.02857em",
        textTransform: 'uppercase'
      }}
    >
        <I18n t="COOKIE_NOTICE_TEXT"/>
        <NavLink className={classes.footer} to="/cookie-notice">
          <I18n t="COOKIE_NOTICE"/>
        </NavLink>.
    </CookieConsent>      

    <div>
      <Typography component="h3" variant="h6" className={classes.title}>
        {I18n.getTranslation(window.location, 'SIDEBAR_TITLE')}
      </Typography>
    </div>
    <div className={classes.demo}>
      <List dense disablePadding>
        <ListItem className={classes.listPadding}>
          <ListItemIcon>
            <NoteIcon color="primary" />
          </ListItemIcon>
          <NavLink to="/browse-claims" className={classes.link} >
            <ListItemText
              primary={I18n.getTranslation(window.location, 'BROWSE_CLAIMS')}
              classes={{ primary: classes.link }}
            />
          </NavLink>
        </ListItem>    
      </List>
    </div>
  </div>
    )
}

export default withRouter(Sidebar);