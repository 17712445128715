import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import I18n from '../I18n';
import firebase from "../Firestore";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ResponsiveDialog from '../components/ResponsiveDialog';

const db = firebase.firestore();
const reportsRef = db.collection("sbbResponse");

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Claims = () => {
	const classes = useStyles();
	const [reports, setReports] = useState([]);
	const [open, setOpen] = useState([]);

	useEffect(() => {
		reportsRef
		.orderBy("timestamp", "desc")
		.limit(10)
		.get()
		.then(querySnapshot => {
		  const data = querySnapshot.docs.map(doc => doc.data());
		  setReports(data);
		});

	}, []);


	const Reports = () => (
		reports.map((val, index) => (
			<div key={index}>
		    <ListItem 
		      dense 
		      button
          	  disableGutters
		       onClick={() => setOpen({[index] : true}) }
		      >
		        <ListItemText primary={val.settings.catName + ' > ' + val.settings.subcatName} secondary={new Date(val.date).toUTCString()} />
		      </ListItem>
		      	<ResponsiveDialog open={open[index] || false} title={val.value.recordNumber}>
					<Typography component="div" variant="subtitle2">
						{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_LOCATION')}
			        </Typography>
			        <div>
			        	{val.value.lossLocation || '-'}
			        </div>
				</ResponsiveDialog>
			</div>
			)
		)
	)

	return (
		<div>
			<Typography component="h1" variant="h4">
				{I18n.getTranslation(window.location, 'BROWSE_CLAIMS')}
			</Typography>
		      <Typography variant="body2">
		        {I18n.getTranslation(window.location, 'LOSS_RECORD_POSSIBLE_MATCHES_FOUND_HINT')}.
		      </Typography>   		
			<List className={classes.root}>
				<Reports/>
			</List>	

		</div>
	)
}

export default Claims;