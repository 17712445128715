import React from "react";

export function useDataCall(url, defaultValue) {
  const [data, setData] = React.useState(defaultValue);

  React.useEffect(() => {
    async function getDataFromAPI() {
      try {
        const res = await fetch(url);
        const data = await res.json();
        setData({
          isLoading: false,
          data
        });
      } catch (e) {
        console.error(e);
        setData({
          isLoading: false,
          data: e
        });
      }
    }
    getDataFromAPI()
  }, []);

  return data;
}