import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCBfGUCC5RXw_CrJ1hw6sPgEG9XydUIYsc",
  authDomain: "ch-lost-found.firebaseapp.com",
  databaseURL: "https://ch-lost-found.firebaseio.com",
  projectId: "ch-lost-found",
  storageBucket: "ch-lost-found.appspot.com",
  messagingSenderId: "825033879147",
  appId: "1:825033879147:web:96a8272239a18f50"
};

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

export default firebase;