import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Sidebar from './Sidebar';
import { withRouter, Route } from 'react-router-dom';
import I18n from '../I18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(5)
    }
  },
}));
const base = '/:locale(en|fr|de)?';

const Main = ({ children }) => {

  	const classes = useStyles();

	return(
		<div>
			<CssBaseline/>
				<Container maxWidth="lg">
					<Grid container spacing={2}>
						<Grid item xs={12} sm={9}>
							<Paper className={classes.paper}>{ children }</Paper>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Route path={base} component={Sidebar} />
						</Grid>
					</Grid>
				</Container>
			<footer>
				<Typography align="center">
					©2019 {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}
				</Typography>
			</footer>	
		</div>		
	)	
}
export default withRouter(Main);