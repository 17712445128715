import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormContext } from "../context/FormContext";
//pickers
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

//select
import Button from "@material-ui/core/Button";

//translations
import I18n from '../I18n';
import history from '../lib/history';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },  
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  datePicker: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  hintText: {
    margin: theme.spacing(1)
  }
}));

const Location = ({ location: { pathname }, match: { params: { locale } } }) => {
  const classes = useStyles();
  const Stations = require('../data/'+locale+'/stations.json');
  const [formData, setFormData] = useContext(FormContext);
  const [handover, setHandover] = useState('');

  const handleLossLocation = e => {
    e.preventDefault();
    const { value, name } = e.target;

    // console.log(value);
    setFormData({
      ...formData,
      [name]: value
    });
    
  };

  const handleHandover = e => {
    const { value } = e.target;
    setHandover(value)    
    // e.preventDefault();

    // // console.log(value);
    setFormData({
      ...formData,
      handoverLocation: {
        ...formData.handoverLocation,
          type: value,
        }
    });
  };  

  const handleStation = e => {
    const { value } = e.target;

    setFormData({
      ...formData,
      handoverLocation: {
          type: 'station',
          stationId: value
        }
    });
  };    

  function handleDateChange(date) {
    date.setHours(0);
 
     setFormData({
      ...formData,
      dateOfLoss: date.toISOString()
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push('/'+locale+'/report/loser');
  };   

  const Handover = () => {
    if(handover === 'station') {
      return (
        <Grid item sm={12} xs={12}>
          <FormControl 
            variant="outlined" 
            required
            className={classes.formControl}
            >
            <TextField
              id="stationId"
              select
              required
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_STATION')}
              className={classes.textField}
              value={formData.handoverLocation.stationId}
              onChange={(e) => {handleStation(e)}}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
              margin="none"
              variant="outlined"
            >
              <option value="" />
              {Stations
                .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                .map((values,index) => (
                  <option key={index} value={values.id}>
                    {values.name}
                  </option>
                )
              )}        
            </TextField>
          </FormControl>    
        </Grid>    
      )
    } else {
      return <div className={classes.hintText}>{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_TYPE_ADDRESS_HINT')}</div>
    }
  }

  return (
    <form onSubmit={handleSubmit} className={classes.formControl}>
      <Typography component="h1" variant="h4">
        {I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_DATA')} 
      </Typography>     
      <FormControl component="fieldset">
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <FormControl 
              variant="outlined" 
              className={classes.formControl} 
              error={formData.dateOfLoss === ""}
              value={formData.dateOfLoss}
              required
            >          
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.datePicker}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label={I18n.getTranslation(window.location, 'VAL_REQUIRED_LOSS_DATE')}
                  format="yyyy-MM-dd"
                  value={formData.dateOfLoss}
                  // InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => {handleDateChange(date)}}
                  fullWidth
                />
              </MuiPickersUtilsProvider>    
            </FormControl>    
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl 
              variant="outlined" 
              required
              className={classes.formControl}
              >
              <TextField
                id="loc"
                name="location"
                select
                required
                label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_LOCATION')}
                className={classes.textField}
                value={formData.location}
                onChange={(e) => {handleLossLocation(e)}}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
                margin="none"
                variant="outlined"
              >
                <option value="" />
                <option value="0">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_TRAIN')}</option>       
                <option value="1">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_AIRPORT')}</option>          
                <option value="2">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_BUS')}</option>          
                <option value="3">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_STREET')}</option>          
                <option value="4">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_UNKNOWN')}</option>          
                <option value="5">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_OTHER')}</option>          
              </TextField>
            </FormControl>           
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl 
              variant="outlined" 
              className={classes.formControl} 
              error={formData.lossLocation === ""}
            >
              <TextField
                id="lossLocation"
                value={formData.lossLocation}
                name="lossLocation"
                className={classes.formControl} 
                label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_LOCATION_DETAILS')}  
                // helperText={formData.lossLocation === "" ? 'Empty field!' : ''}
                // error={formData.lossLocation === ""}
                inputProps={{ maxLength: 99 }}
                fullWidth
                multiline
                onChange={(e) => handleLossLocation(e)}
                margin="none"
                variant="outlined"
              />
            </FormControl>  
          </Grid>
          <Grid item sm={12} xs={12}>          
            <FormControl 
              variant="outlined" 
              className={classes.formControl} 
              required
              // error={ index in formData.lossItems.properties ? false : true }
            >
              <FormLabel component="legend">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_TYPE')}</FormLabel>
              <RadioGroup aria-label="handover" name="handover" value={formData.handoverLocation.type} onChange={(e) => {handleHandover(e)}}>
                <FormControlLabel
                  value="station"
                  control={<Radio color="primary" />}
                  label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_TYPE_STATION')} 
                  labelPlacement="end"
                  margin="none"
                  variant="outlined"
                />
                <FormControlLabel
                  value="address"
                  control={<Radio color="primary" />}
                  label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_TYPE_ADDRESS')} 
                  labelPlacement="end"
                  margin="none"
                  variant="outlined"                
                />              
              </RadioGroup>
            </FormControl>   
          </Grid>
            <Handover/>
        </Grid> 
      </FormControl>
      <div className={classes.buttons}>
        <Button onClick={() => {history.push('/'+locale+'/report/lost-property')}} className={classes.button}>
          {I18n.getTranslation(window.location, 'GLOBAL_BACK')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        >
          {I18n.getTranslation(window.location, 'GLOBAL_NEXT')}
          </Button>
      </div>      
    </form>
  );
}

export default Location;