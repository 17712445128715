import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import Grid from '@material-ui/core/Grid';
// import { FormContext } from "../context/FormContext";
// import firebase from "../Firestore";
// import { possiblematchesCall } from "../helpers/apiCalls";
// import Loading from '../components/Loading';
import I18n from '../I18n';

// const db = firebase.firestore();

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: 14,
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const Success = ({match, location}) => {
  const classes = useStyles();
  // const [possiblematches, setPossiblematches] = useState(null);
  // const [reportId] = useState(match.params.reportId)

  /*
  useEffect(() => {

    async function getMatches() {

      var docRef = await db.collection(process.env.REACT_APP_REPORTS_DB).doc(reportId);

      docRef.get().then(function(doc) {
          if (doc.exists) {
                possiblematchesCall(doc.data().formData)
                .then(function(data) {
                  setPossiblematches(data);
                  // console.log(data);
                }).catch(function(error) {
                  console.log(error);
                });             
          } else {
              // doc.data() will be undefined in this case
              setPossiblematches('no results found');
          }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    }

    getMatches()

  }, [reportId]);
  */


  /*
  const Results = () => {
    if(possiblematches === null) {
      return <Loading/>
    } else if(Array.isArray(possiblematches) && possiblematches.length > 0) {
      return <FoundItems possiblematches={possiblematches}/>
    } else {
      return <div className={classes.grid}>{I18n.getTranslation(window.location, 'LOSS_RECORD_POSSIBLE_MATCHES_NOTHING_FOUND')}</div>
    }
  }
  */
  
  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" gutterBottom>
        {I18n.getTranslation(window.location, 'LOSS_RECORD_THANK_YOU')}
      </Typography> 
    </React.Fragment>
  );

}
/*
const FoundItems = (props) => {
  const classes = useStyles();

  return(
    <Grid container spacing={1} className={classes.grid}>
      {props.possiblematches
            .map((values,index) => (
              <Grid item md={6} xs={12} key={index}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {values.recordNumber}
                    </Typography>
                    <React.Fragment>
                      <div className={classes.pos} dangerouslySetInnerHTML={{ __html: values.summary.replace(/\r/g, "</br>") }} />
                    </React.Fragment>
                  </CardContent>
                </Card>
              </Grid>                             
            )
          )
      }
    </Grid>
  )
}
*/
export default Success;