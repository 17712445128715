import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

//translations
import I18n from '../I18n';
import history from '../lib/history';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { FormContext } from "../context/FormContext";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      // width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  list: {
    padding: 0
  }, 
  catName: {
    fontWeight: 800,
    color: 'red'
  },
  home_text: {
    // paddingLeft: theme.spacing(2)
  },
  icon: {
    color: 'red',
    width: 20
  },
  listitemtext: {
    minWidth: 35
  }  

}));

const Home = ({ location: { pathname }, match: { params: { locale } } }) => {
  const classes = useStyles();
  const [formData, setFormData] = useContext(FormContext);
  const [open, setOpen] = useState([]);
  const [categories, setCategories] = useState([]);
  const [catName, setCatName] = useState('');
  const [filteredCats, setFilteredCats] = useState([]);

  // Handle collapse
  function handleCollapse(itemId) {
    const status = open[itemId] ? false : true;
    setOpen({[itemId] : status})
  }

  useEffect(() => {
    const json = require('../data/'+locale+'/categories.json');
    setCategories(json);
    setFilteredCats(json);
  }, [locale]);

  // manage input search
  const handleSearch = (e) => {

    var updatedList = categories;
    updatedList = updatedList.filter(function(item){
      return item.name.toLowerCase().search(
        e.target.value.toLowerCase()) !== -1;
    });
    setFilteredCats(updatedList);

  }

  const updateState = (cat,subcat,subcatName) => {
    setFormData({
      ...formData,
      type: 'sbb',
      settings: {
        ...formData.settings,
        catName: catName,
        subcatName: subcatName
      },
      lossItems: [{
        'categoryId': cat,
        'subCategoryId': subcat
      }]
    });

    // pass to next page
    history.push('/'+locale+'/report/lost-property')
  };

  const ListItems = (props) => (
      filteredCats
      .map((cat, index) => (
        <div key={cat.id} >
          <ListItem 
          button
          disableGutters 
          onClick={() => {handleCollapse(cat.id); setCatName(cat.name)}}
          >
            <ListItemText primaryTypographyProps={{ style: {fontWeight: 600} }} primary={cat.name} />
            {open[cat.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[cat.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Subcatloop locale={locale} catId={cat.id}/>
            </List>
          </Collapse>          
          <Divider />
        </div>
      ))
  );

  const Subcatloop = (props) => {

    const subcats = require('../data/'+locale+'/categories/'+props.catId+'.json');

    return(
      subcats.map((subcat, index) => (
          <ListItem 
          	key={index} 
          	disableGutters
          	button 
          	onClick={() => {updateState(props.catId, subcat.id, subcat.name)}}
          >
            <ListItemIcon classes={{root: classes.listitemtext}} className={classes.icon}>
              <ChevronRight />
            </ListItemIcon>
            <ListItemText primary={subcat.name} />
          </ListItem>     
        ))
      )
  }  

  return (
    <div>
      <main className={classes.layout}>
        <Typography className={classes.title} component="h1" variant="h4" align="center">
          <I18n t="GLOBAL_APP_TITLE" />
        </Typography>   
        <Typography className={classes.title} component="h2" variant="subtitle1" align="center">
          <I18n t="SUBTITLE" />
        </Typography>             
        <React.Fragment>
          <Typography variant="body2" gutterBottom className={classes.home_text} align="center">
            {I18n.getTranslation(window.location, 'HOME_TEXT')}
          </Typography>    
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-name"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOOKING_FOR')}
              className={classes.textField}
              placeholder={I18n.getTranslation(window.location, 'LOSS_RECORD_CATEGORY_SEARCH_PLACEHOLDER')}
              onChange={(e) => {handleSearch(e)}}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </form>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItems locale={locale} />
          </List>
        </React.Fragment>
      </main>
    </div>
  );
}

export default Home;
