const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

// validate zipCodes from different countries
export function zipValidation(zipCode) {
    try{
        return /\d/.test(zipCode) // for now, just check if it has Number (same validation as SBB)
    } catch (error) {
        return error
    }
}

export function phoneValidation(phoneNumber, countryCode = 'CH') {

    try {
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

        if(!phoneUtil.isValidNumber(number)) {
        	return false
        } 

        return true

    } catch (error){
        return error;
    }
}

export function isMobile(phoneNumber, countryCode = 'CH') {

    try {
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

        if(phoneUtil.getNumberType(number)) {
        	return true
        } 

        return false

    } catch (error){
        return error;
    }
}

export async function validateLoser(values) {
    let errors = {}

    // zipCode validation, if it fails, assign error to true
    if (await !zipValidation(values.address.zipCode)) {
        errors.zipCode = true;
    }
    // mobileNumber validation, if it fails, assign error to true
    if(await !phoneValidation(values.mobileNumber)) {
        errors.mobileNumber = true;
    }

    return errors
}

export async function validateLostProperty(values) {
    let errors = {}

    // // zipCode validation, if it fails, assign error to true
    // if (await !zipValidation(values.address.zipCode)) {
    //     errors.zipCode = true;
    // }
    // // mobileNumber validation, if it fails, assign error to true
    // if(await !phoneValidation(values.mobileNumber)) {
    //     errors.mobileNumber = true;
    // }

    return errors
}