import {useState, useEffect, useContext} from "react";
import { FormContext } from "../context/FormContext";
import history from '../lib/history';

function useLoserValidation(initialState, validate, locale) {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useContext(FormContext);

  useEffect((isSubmitting) => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setSubmitting(false);
      } else {
        setSubmitting(true);
      }
    }
  }, [errors]);

  function handleChange(event, type) {
    const {value, name} = event.target;
    event.persist();

    if(type === 'address') {
      setValues({ ...values, address:{...values.address, [name]: value} });
    } else {
      setValues({...values, [name]: value });
    }
  }

  async function handleBlur() {
    const validationErrors = await validate(values);
    // console.log(validationErrors)
    setErrors(validationErrors);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const validationErrors = await validate(values);
    setErrors(validationErrors);

    // no errors, we can submit
    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      // console.log(values)
      setFormData({...formData, loser: values });
      history.push('/'+locale+'/report/overview')
    } else {
      setSubmitting(true);
    }

  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting
  };
}

export default useLoserValidation;
