import React, { useState, useContext, useEffect } from "react";
import { FormContext } from "../context/FormContext";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//forms
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { CirclePicker } from 'react-color'
import Divider from '@material-ui/core/Divider';
import Loading from "../components/Loading";

import {isEmpty} from '../helpers/functions'; //remove

//translations
import I18n from '../I18n';
import history from '../lib/history';
import useLostpropertyValidation from "../helpers/useLostpropertyValidation";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  formControl: {
    // marginBottom: theme.spacing(1),
    width: '100%',
  },
  colorPicker: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  }
}));

const List = (props) => {
  const classes = useStyles();
  const json = require('../data/'+props.locale+'/categories/subcategories/'+props.formData["lossItems"][0].subCategoryId+'.json');
  const [loading, setLoading] = useState(true);

  const {
      handleSubmit,
      handleChange,
      handleColor,
      handleCurrency,
      properties,
      setProperties
    } = useLostpropertyValidation({ ...props.formData.lossItems[0].properties || {} }, props.locale);

  useEffect(() => {

  	// build the properties object with 0: {}, 1: {}, etc. This is important for the SBB API
  	async function defaultValues () {
  		const pArray = await json.properties.map(async (obj, index) => {
  			setProperties(properties => ({
  				...properties, 
  				[index]: {} 
  			}));   
  		});

  		await Promise.all(pArray);
  		setLoading(false)
  	}   

  	if(isEmpty(properties)){
  	    defaultValues()
  	} else {
  		setLoading(false)
  	}

  }, []) 

  // if defaultValues are assigned, load the form
  if(loading) {
      return <Loading/>
  }

  return (
    <form onSubmit={handleSubmit} className={classes.formControl}>
        <Grid container spacing={1}>
          {json.properties.map((obj, index) => {
          
                switch (obj.type) {
                  case 'easyFindCode':
                    return ''
                  case 'enum':
                    return(
                      <Grid key={index} item sm={12} xs={12}>
                        <FormControl 
                          variant="outlined" 
                          required={obj.isRequired}
                          className={classes.formControl}
                          >
                          <TextField
                            id={obj.name}
                            select
                            required={obj.isRequired}
                            label={obj.question}
                            // className={classes.textField}
                            value={properties[index].value || ''}
                            onChange={(e) => handleChange(e, index)}
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
                            margin="none"
                            variant="outlined"
                          >
                            <option value="" />
                            {obj.allowedValues
                              .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                              .map((values,index) => (
                                <option key={index} value={values.id}>
                                  {values.name}
                                </option>                                
                              )
                            )}                                   
                          </TextField>
                        </FormControl>                        
                      </Grid>
                    )
                  case 'boolean':
                    return (
                      <Grid item sm={12} xs={12} key={index}>
                        <FormControl 
                          variant="outlined" 
                          required={obj.isRequired}
                          className={classes.formControl}
                          >
                          <TextField
                            id={obj.name}
                            select
                            required={obj.isRequired}
                            label={obj.question}
                            // className={classes.textField}
                            name="boolean"
                            value={properties[index].value || ''}
                            onChange={(e) => handleChange(e, index)}
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
                            margin="none"
                            variant="outlined"
                          >
                            <option value="" />
                            <option value={true}>{I18n.getTranslation(window.location, 'GLOBAL_YES')}</option>
                            <option value={false}>{I18n.getTranslation(window.location, 'GLOBAL_NO')}</option>                                  
                          </TextField>
                        </FormControl>                           
                      </Grid>
                    )          
                  case 'singleLineText':
                  case 'multiLineText':
                    return (
                      <Grid item sm={12} xs={12} key={index}>
                        <FormControl 
                          variant="outlined" 
                          required={obj.isRequired} 
                          className={classes.formControl} 
                          fullWidth

                        >
                          <TextField
                            id={obj.name}
                            name={obj.name}
                            label={obj.name}
                            // value={ if(properties[index].value !== "undefined") { properties[index].value} else {''} }
                            value={properties[index].value || ''}
                            fullWidth
                            multiline
                            onChange={(e) => handleChange(e, index)}
                            margin="none"
                            variant="outlined"
                          />
                        </FormControl>                        
                      </Grid>
                    );             
                  case 'color':
                      if(obj.isRequired && isEmpty(properties[index])) {
                          setProperties(properties => ({
                             ...properties, 
                             [index]: {
                               value: "#000000" //assign default value to color
                             } 
                           }))
                      }
                    return (
                      <Grid item sm={12} xs={12} key={index}>
                        <FormControl 
                          required={obj.isRequired} 
                          className={classes.formControl}
                          error={obj.isRequired ? true : false}
                          >
                          <InputLabel htmlFor={obj.name}>{obj.question}</InputLabel>
                          <CirclePicker
                            id={obj.name}
                            name="color"
                            color={properties[index].value || ''}
                            className={classes.colorPicker}
                            onChange={(e) => handleColor(e, index)}
                            colors={['#FFFFFF', '#C0C0C0', '#808080', '#000000', '#FF0000', '#800000', '#FFFF00', '#808000', '#00FF00', '#008000', '#00FFFF', '#008080', '#0000FF', '#000080', '#FF00FF', '#800080']}
                          />
                            <Divider/>
                        </FormControl>
                      </Grid>
                    )  
                  case 'currency':             
                    return (
                      <Grid item sm={12} xs={12} key={index}>
                        <FormControl variant="outlined" required={obj.isRequired} className={classes.formControl}>
                          <TextField
                            id={obj.name}             
                            label={obj.name}
                            helperText={obj.question}
                            name="currency"
                            // value={formData.lossItems.properties[`${index}`]}
                            value={properties[index].value || ''}
                            // onChange={(e) => setCurrency(e, index)}
                            onChange={(e) => handleCurrency(e, index)}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="none"
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    )          
                  default:
                    return <div key={index}>rest: {obj.type}</div>;
                }
          
              })}        
        </Grid>
        <div className={classes.buttons}>
          <Button onClick={() => {history.push('/' + props.locale)}} className={classes.button}>
            {I18n.getTranslation(window.location, 'GLOBAL_BACK')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            {I18n.getTranslation(window.location, 'GLOBAL_NEXT')}
            </Button>
        </div>
    </form>
  )
};

const StepLostProperty = ({ location: { pathname }, match: { params: { locale } } }) => {
  const classes = useStyles();
  const [formData] = useContext(FormContext);

  if(formData.lossItems){
    return (
      <React.Fragment>
        <Typography className={classes.title} component="h2" variant="h6">
          {formData.settings.catName}
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          {formData.settings.subcatName}
        </Typography>
        <List locale={locale} formData={formData}/>
      </React.Fragment>
    );
  }
  
  history.push('/'+locale)
  return ''

}

export default StepLostProperty;