import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useDataCall } from "../../helpers/useDataCall"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from "react-helmet-async";

import I18n from '../../I18n';
// import history from '../../lib/history';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },  
  menu: {
    width: 200,
  },
  list: {
    padding: 0
  }, 
  catName: {
    fontWeight: 800,
    color: 'red'
  },
  title: {
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase'
  },
  table: {
    minWidth: 650,
  },  
}));

const Airports = ({ location: { pathname }, match: { params: { locale } }, match }) => {

	return(
		<div>
	        <Helmet>
	            <title> {I18n.getTranslation(window.location, 'LOCATION_AIRPORTS')} | {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}</title>
	        </Helmet>			
			<AirportsList match={match}/>    
		</div>
	)
}

const AirportsList = (props) => {
	const classes = useStyles();
	const apiEndpoint = "https://data.lostfound.services/wp-json/wp/v2/airports";
  	const fetchResponse = useDataCall(apiEndpoint, {isLoading: true, data: null});
  	const [airports, setAirports] = useState([]);
  	const [filteredAirports, setFilteredAirports] = useState(null);	

	useEffect(() => {
	    setAirports(fetchResponse.data);
	    setFilteredAirports(fetchResponse.data);
	}, [fetchResponse]);

	if (!filteredAirports || fetchResponse.isLoading) {
		return <CircularProgress />
	}

	const handleSearch = (e) => {
	    var updatedList = airports;
	    updatedList = updatedList.filter(function(item){
	      return item.title.rendered.toLowerCase().search(
	        e.target.value.toLowerCase()) !== -1;
	    });
	    setFilteredAirports(updatedList);
	}	

	return(
		<div>
			<Typography component="h1" variant="h4">
				{I18n.getTranslation(window.location, 'LOCATION_AIRPORTS')}
			</Typography>
			<form className={classes.container} noValidate autoComplete="off">
				<TextField
				  id="outlined-name"
				  label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOOKING_FOR')}
				  className={classes.textField}
				  placeholder={I18n.getTranslation(window.location, 'LOSS_RECORD_CATEGORY_SEARCH_PLACEHOLDER')}
				  onChange={(e) => {handleSearch(e)}}
				  fullWidth
				  margin="normal"
				  variant="outlined"
				/>
			</form>			
			<div>
			    {filteredAirports
			      .map((values,index) => (
			      	<div key={index}>
			          <ListItem 
			          	button
			          	{...{ to: { pathname: `${props.match.url}/${values.slug}`}}}
			          	component={Link}
			          >
			            <ListItemText primary={values.title.rendered} />
			            <ExpandMore />
			          </ListItem>   		
			        </div>
			      )
			    )}
			</div>
		</div>
	)		
}

export default withRouter(Airports);