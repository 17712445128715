import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useDataCall } from "../../helpers/useDataCall"
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Helmet } from "react-helmet-async";
import FormControl from '@material-ui/core/FormControl';
import { FormContext } from "../../context/FormContext";

//pickers
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import I18n from '../../I18n';
import history from '../../lib/history';

const useStyles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(3),
		textTransform: 'uppercase'
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%',
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end"
	},  
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1)
	},
	datePicker: {
		marginTop: theme.spacing(1),
		width: '100%'
	}	
}));

const Airport = ({ location: { pathname }, match: { params: { locale } }, match }) => {
	const classes = useStyles();
	const apiEndpoint = "https://data.lostfound.services/wp-json/wp/v2/airports?slug=" + match.params.slug;
  	const fetchResponse = useDataCall(apiEndpoint, {isLoading: true, data: null});	
	const [airportData, setAirportData] = useState(null);

	useEffect(() => {
		setAirportData(fetchResponse.data)
	}, [fetchResponse]);

	if (!airportData || fetchResponse.isLoading) {
		return <CircularProgress />
	}

	return(
		<div>
	        <Helmet>
	            <title>{airportData[0].title.rendered} {I18n.getTranslation(window.location, 'SEO_TITLE')} - {I18n.getTranslation(window.location, 'LOCATION_AIRPORTS')} | {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}</title>
	        	<meta name="description" content={`${airportData[0].title.rendered} ${I18n.getTranslation(window.location, 'SEO_TITLE')} ${I18n.getTranslation(window.location, 'AIRPORTS_HOME_TEXT')}`}/>
	        </Helmet>	
			<Typography className={classes.title} component="h1" variant="h4" align="center">
				{airportData[0].title.rendered}
			</Typography>	
			<Typography variant="body2" gutterBottom className={classes.home_text} align="center">
				{I18n.getTranslation(window.location, 'AIRPORTS_HOME_TEXT')}
			</Typography> 						
			<Typography component="h2" variant="h5">
				{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_DESCRIPTION')} 
			</Typography>   
			<AirportsDetails airportData={airportData} locale={locale} airportCode={airportData[0].acf.AIRPORT_CODE}/>
		</div>
	)
}

const AirportsDetails = (props) => {
	const classes = useStyles();
	const categories = require('../../data/'+props.locale+'/airports/'+props.airportCode+'/categories.json');
	const subCategories = require('../../data/'+props.locale+'/airports/'+props.airportCode+'/subCategories.json');
	const [formData, setFormData] = useContext(FormContext);

	useEffect(() => {

		setFormData({
		  ...formData,
		  submitted: false,  
		  type: 'airport',
		  airport: {
		    ...formData.airport,
		      'airportCode': props.airportCode
		    }
		})

	}, []);	

	const handleChange = e => {
		const { name, value, options } = e.target;

		setFormData({
		  ...formData,
	      settings: {
	        catName: (name === 'ddlMaincategory') ? options[e.target.selectedIndex].text : formData.settings.catName,
	        subcatName: (name === 'ItemCategory_fk') ? options[e.target.selectedIndex].text : formData.settings.subcatName,
	      },			  
		  airport: {
		    ...formData.airport,
		      [name]: value
		    }
		})
	}; 

	function handleDateChange(date) {
		date.setHours(0);

		 setFormData({
		  ...formData,
		  dateOfLoss: date.toISOString()
		});
	}	

	const handleSubmit = (e) => {
		e.preventDefault();
		history.push('/'+props.locale+'/report/loser');
	};

	return (
		<form onSubmit={handleSubmit} className={classes.formControl}>
			<FormControl component="fieldset" fullWidth>
		        <Grid container spacing={1}>	        
		          <Grid item sm={12} xs={12}>
		            <FormControl 
		              variant="outlined" 
		              required
		              className={classes.formControl}
		              >
		              <TextField
		                id="loc"
		                name="ddlMaincategory"
		                select
		                required
		                label={I18n.getTranslation(window.location, 'LOSS_ITEM_MAIN_CATEGORY')}
		                className={classes.textField}
		                value={formData.airport.ddlMaincategory || ''}
		                onChange={(e) => {handleChange(e)}}
		                SelectProps={{
		                  native: true,
		                  MenuProps: {
		                    className: classes.menu,
		                  },
		                }}
		                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
		                margin="none"
		                variant="outlined"
		              >
						<option value="" />
			              {categories.data
			                // .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			                .map((values,index) => (
				                  <option key={index} value={values.ItemCategory_ID}>
				                    {values.LocalizedValue}
				                  </option>
				                )
				              )
				            }       
		              </TextField>
		            </FormControl>           
		          </Grid>	
		          <Grid item sm={12} xs={12}>
		            <FormControl 
		              variant="outlined" 
		              required
		              className={classes.formControl}
		              >
		              <TextField
		                id="loc"
		                name="ItemCategory_fk"
		                select
		                required
		                label={I18n.getTranslation(window.location, 'LOSS_ITEM_SUB_CATEGORY')}
		                className={classes.textField}
		                value={formData.airport.ItemCategory_fk || ''}
		                onChange={(e) => {handleChange(e)}}
		                SelectProps={{
		                  native: true,
		                  MenuProps: {
		                    className: classes.menu,
		                  },
		                }}
		                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
		                margin="none"
		                variant="outlined"
		              >
		                <option value="" />
			              {subCategories.data
			                .filter(temp => temp.ItemCategory_fk === formData.airport.ddlMaincategory)
			                .map((values,index) => (
			                  <option key={index} value={values.ItemCategory_ID}>
			                    {values.LocalizedValue}
			                  </option>
			                )
			              )}       
		              </TextField>
		            </FormControl>           
		          </Grid>	
                  <Grid item sm={12} xs={12} >
                    <FormControl 
                      variant="outlined" 
                      required
                      className={classes.formControl} 
                      fullWidth

                    >
                      <TextField
                      	required
                        id="Description"
                        name="Description"
                        label={I18n.getTranslation(window.location, 'LOSS_ITEM_DESCRIPTION')}
                        value={formData.airport.Description || ''}
                        fullWidth
                        multiline
                        rows="3"
                        onChange={(e) => handleChange(e)}
                        margin="none"
                        variant="outlined"
                      />
                    </FormControl>                        
                  </Grid>	
                  <Grid item sm={12} xs={12}>
					<Typography component="h2" variant="h5">
						{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_DATA')} 
					</Typography>                    	
                  </Grid>	
		          <Grid item sm={12} xs={12}>
		            <FormControl 
		              variant="outlined" 
		              className={classes.formControl} 
		              // error={formData.dateOfLoss === ""}
		              // value={formData.dateOfLoss}
		              required
		            >          
		              <MuiPickersUtilsProvider utils={DateFnsUtils}>
		                <DatePicker
		                  className={classes.datePicker}
		                  autoOk
		                  variant="inline"
		                  inputVariant="outlined"
		                  label={I18n.getTranslation(window.location, 'VAL_REQUIRED_LOSS_DATE')}
		                  format="yyyy-MM-dd"
		                  value={formData.dateOfLoss || ''}
		                  // InputAdornmentProps={{ position: "start" }}
		                  onChange={(date) => {handleDateChange(date)}}
		                  fullWidth
		                />
		              </MuiPickersUtilsProvider>    
		            </FormControl>    
		          </Grid>                            		        
		          <Grid item sm={12} xs={12}>
		            <FormControl 
		              variant="outlined" 
		              required
		              className={classes.formControl}
		              >
		              <TextField
		                id="AirportPlane"
		                name="AirportPlane"
		                select
		                required
		                label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_LOCATION')} 
		                className={classes.textField}
		                value={formData.airport.AirportPlane}
		                onChange={(e) => {handleChange(e)}}
		                SelectProps={{
		                  native: true,
		                  MenuProps: {
		                    className: classes.menu,
		                  },
		                }}
		                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
		                margin="none"
		                variant="outlined"
		              >
		                <option value="" />
		                <option value="18bba3cc-98f7-41a7-a7be-30fd7bb79634">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_AIRPORT')}</option>       
		                <option value="6acd768a-6f9f-412f-b980-cd3c1dcbc9aa">{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_PLANE')}</option>
		              </TextField>
		            </FormControl>           
		          </Grid>

		          <AirportPlane formData={formData} setFormData={setFormData} handleChange={handleChange} locale={props.locale} airportCode={props.airportCode}/>		 

		        </Grid>
	        </FormControl>	
			<div className={classes.buttons}>
				<Button
				  variant="contained"
				  color="primary"
				  type="submit"
				  className={classes.button}
				>
			  		{I18n.getTranslation(window.location, 'GLOBAL_NEXT')}
			  	</Button>
			</div>	        
	    </form>		
	)
}

const AirportPlane = (props) => {
	const classes = useStyles();
	const places = require('../../data/'+props.locale+'/airports/'+props.airportCode+'/placesSubcategories.json');
	const airlines = require('../../data/'+props.locale+'/airports/'+props.airportCode+'/airlines.json');

	function handleDateChange(date) {

		props.setFormData({
		  ...props.formData,
		  airport: {
		    ...props.formData.airport,
		     'FlightDate': date.toISOString()
		    }
		});
	}	
	
	if(props.formData.airport.AirportPlane === '18bba3cc-98f7-41a7-a7be-30fd7bb79634') {
		return (
          <Grid item sm={12} xs={12}>
            <FormControl 
              variant="outlined" 
              required
              className={classes.formControl}
              >
              <TextField
                id="LossPlaceFK"
                name="LossPlaceFK"
                select
                required
                label={I18n.getTranslation(window.location, 'LOSS_ITEM_SUB_LOCATION')} 
                className={classes.textField}
                value={props.formData.airport.LossPlaceFK || ''}
                onChange={(e) => {props.handleChange(e)}}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
                margin="none"
                variant="outlined"
              >
                <option value="" />
	              {places.data
	                .map((values,index) => (
	                  <option key={index} value={values.LossPlace_ID}>
	                    {values.LocalizedValue}
	                  </option>
	                )
	              )}       
              </TextField>
            </FormControl>           
          </Grid>
		)
	} else if(props.formData.airport.AirportPlane === '6acd768a-6f9f-412f-b980-cd3c1dcbc9aa') {
		return (
			<React.Fragment>
				<Grid item sm={12} xs={12}>
		            <FormControl 
		              variant="outlined" 
		              className={classes.formControl}
		              >
		              <TextField
		                id="AirlineFK"
		                name="AirlineFK"
		                select
		                required
		                label={I18n.getTranslation(window.location, 'LOCATION_AIRLINE')}
		                className={classes.textField}
		                value={props.formData.airport.AirlineFK || ''}
		                onChange={(e) => {props.handleChange(e)}}
		                SelectProps={{
		                  native: true,
		                  MenuProps: {
		                    className: classes.menu,
		                  },
		                }}
		                // helperText={I18n.getTranslation(window.location, 'VAL_REQUIRED_HANDOVER_STATION')}
		                margin="none"
		                variant="outlined"
		              >
		                <option value="" />
			              {airlines.data
			                .map((values,index) => (
			                  <option key={index} value={values.UID}>
			                    {values.AirlineName}
			                  </option>
			                )
			              )}       
		              </TextField>
		            </FormControl>           
	          </Grid>
              <Grid item sm={12} xs={12} >
                <FormControl 
                  variant="outlined" 
                  className={classes.formControl} 
                  fullWidth

                >
                  <TextField
                  	required
                    id="FlightNumber"
                    name="FlightNumber"
                    label={I18n.getTranslation(window.location, 'LOCATION_FLIGHT_NUMBER')}
                    value={props.formData.airport.FlightNumber || ''}
                    fullWidth
                    onChange={(e) => props.handleChange(e)}
                    margin="none"
                    variant="outlined"
                  />
                </FormControl>                        
              </Grid>	
	          <Grid item sm={12} xs={12}>
	            <FormControl 
	              variant="outlined" 
	              className={classes.formControl} 
	              required
	            >          
	              <MuiPickersUtilsProvider utils={DateFnsUtils}>
	                <DatePicker
	                  className={classes.datePicker}
	                  autoOk
	                  variant="inline"
	                  inputVariant="outlined"
	                  label={I18n.getTranslation(window.location, 'LOCATION_FLIGHT_DATE')}
	                  format="yyyy-MM-dd"
	                  value={props.formData.airport.FlightDate || ''}
	                  // InputAdornmentProps={{ position: "start" }}
	                  onChange={(date) => {handleDateChange(date)}}
	                  fullWidth
	                />
	              </MuiPickersUtilsProvider>    
	            </FormControl>    
	          </Grid>              
         	</React.Fragment>          
		)
	} else {
		return ''
	}


}

export default Airport;