import React, { useState, useContext } from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormContext } from "../context/FormContext";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PayPalButton } from "react-paypal-button-v2";
import Button from "@material-ui/core/Button";
import Loading from "../components/Loading";
import { NavLink } from '../lib';
import history from '../lib/history';
import PaypalImg from '../img/paypal_btn.png';
import { lossrecordsCall } from "../helpers/apiCalls";
import firebase from "../Firestore";

//translations
import I18n from '../I18n';

const db = firebase.firestore();

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  leadTitle: {
    marginBottom: theme.spacing(2) 
  },
  title: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },  
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  paypalBtn: {
    cursor: 'pointer'
  }
}));

const PersonalData = (props) => {
  const classes = useStyles();
  const [formData] = useContext(FormContext);
  const addresses = [formData.loser.address.street, formData.loser.address.streetNumber];

  const HasData = () => {
    if(formData.loser.firstName) {
      return(    
        <div>
          <Typography gutterBottom>{formData.loser.firstName} {formData.loser.lastName}</Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
          <Typography gutterBottom>{formData.loser.address.zipCode} {formData.loser.address.city}</Typography>
          <Typography gutterBottom>{formData.loser.mailAddress}</Typography>
          <Typography gutterBottom>{formData.loser.mobileNumber}</Typography>
        </div>
      )
    } else {
      return (
          <NavLink to={'/report/loser'} className={classes.link} >
            <EditIcon color="primary"/>
          </NavLink>
        )
    }
  }

  return (

    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        {I18n.getTranslation(window.location, 'LOSS_RECORD_LOSER_DATA')}
      </Typography>
      <HasData/>
    </Grid>

  )
}

const Review = ({ location: { pathname }, match: { params: { locale } } }) => {
  const classes = useStyles();
  const [formData] = useContext(FormContext);
  const [price] = useState(20);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const date = new Date().toISOString();
  const timestamp = Date.now();

  const products = [
    { name: I18n.getTranslation(window.location, 'LOST_PROPERTY_REPORT'), desc: (formData.settings) ? formData.settings.catName +' > ' + formData.settings.subcatName : '', price: price + ' CHF' },
    { name: I18n.getTranslation(window.location, 'PAYMENT_FEE'), desc: '', price: 1.51 + ' CHF' },
  ];

  const handleTerms = () => {

    //only accept terms if the following items exist in the formData:
    if(!formData.loser.firstName) {
      alert(I18n.getTranslation(window.location, 'EMPTY_LOSER'))
    // } else if(!formData.lossItems) {
    //   alert(I18n.getTranslation(window.location, 'EMPTY_LOSS_ITEMS'))
    } else {

      // set/unset terms
      setTerms(!terms);
      
    }
  }

  const Payment = () => {
    if(terms === true) {
      return (
        <PayPalButton
            currency="CHF"
            amount={21.51}
            onSuccess={(details, data) => {
            	
              setLoading(true)

      				async function doWork() {
      					try {

                  await db.collection(process.env.REACT_APP_REPORTS_DB).add({date: date, timestamp: timestamp, formData}).then(async function(docRef) {

                      // console.log("Document written with ID: ", docRef.id);

                      await db.collection(process.env.REACT_APP_PAYMENTS_DB).add({date: date, timestamp: timestamp, report: docRef.id, details});
                      // console.log('payment saved')

                      if(formData.type === 'sbb') {
                          const value = await lossrecordsCall(formData);
                          // console.log('queried lossrecordsCall(), now save response');
    
                          await db.collection(process.env.REACT_APP_SBBRESPONSE_DB).add({date: date, timestamp: timestamp, orderId: data.orderID, report: docRef.id, settings: formData.settings, value});
                          // console.log('response saved');
                      }
                      
                      await history.push('/'+locale+'/report/success/'+docRef.id)

                  })

      					} catch (err) {
      						console.error(err)
      					}

      				}

              doWork()

            }}
            catchError={(object) => {
                history.push('/'+locale+'/report/cancel')
              }
            }
            options={{
              clientId: process.env.REACT_APP_PAYPAL_KEY, // generating key from helper according to environment
              currency: "CHF"
            }}
          />
      )
    }
    return <img onClick={() => {alert(I18n.getTranslation(window.location, 'TERMS_AND_CONDITIONS_ACCEPT'))}} src={PaypalImg} alt="Paypal" className={classes.paypalBtn}/>
  }  

  const PaymentButton = () => {
    if(loading) {
      return <Loading />
    } else {
      return <Payment/>
    }
  }

  return (
	<React.Fragment>
      <Typography component="h1" variant="h4" className={classes.leadTitle}>
        {I18n.getTranslation(window.location, 'LOSS_RECORD_OVERVIEW')}
      </Typography>
      <Divider/>
      <Typography variant="h6" gutterBottom className={classes.title}>
        {I18n.getTranslation(window.location, 'LOSS_RECORD_ITEMS')} 
      </Typography>      
      <List disablePadding>
        {products.map(product => (
          <ListItem dense className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary={I18n.getTranslation(window.location, 'TOTAL')} classes={{ primary: classes.total }}/>
          <Typography variant="subtitle1" className={classes.total}>
            {21.51} CHF
          </Typography>
        </ListItem>
      </List>
      <Divider/>
      <Grid container spacing={2}>

        <PersonalData locale={locale}/>
          
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {I18n.getTranslation(window.location, 'PAYMENT_DETAILS')}
          </Typography> 
          <Grid container justify = "center">

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox 
                  checked={terms}
                  color="primary" 
                  onChange={handleTerms} 
                  value={terms} />
                }
                label={<Typography variant="body2">
                        <I18n t="AGREE_TERMS_1"/>
                        <NavLink to={'/terms-and-conditions'} className={classes.link} >
                          <I18n t="AGREE_TERMS_2"/> 
                        </NavLink>
                        <I18n t="AGREE_TERMS_3"/> 
                        <NavLink to={'/privacy-notice'} className={classes.link} >
                          <I18n t="AGREE_TERMS_4"/>
                        </NavLink>
                        <I18n t="AGREE_TERMS_5"/> 
                      </Typography>
                    } 
              />
            </FormGroup>

              <PaymentButton />


          </Grid>       
        </Grid>
      </Grid>
        <div className={classes.buttons}>
            <Button onClick={() => {history.push('/'+locale+'/report/loser')}} className={classes.button}>
              {I18n.getTranslation(window.location, 'GLOBAL_BACK')}
            </Button>
        </div>         
    </React.Fragment>
  );
}

export default Review;