import React, { 
    useContext 
  } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FormContext } from "../context/FormContext";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import { validateLoser } from '../helpers/functions';
import useLoserValidation from "../helpers/useLoserValidation";
//translations
import I18n from '../I18n';
import history from '../lib/history';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: '100%',
    marginBottom: '4px'
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },  
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

export default function Loser({ location: { pathname }, match: { params: { locale } } }) {
  const classes = useStyles(); 
  const countries = require('../data/'+locale+'/countries.json');
  const [formData] = useContext(FormContext);

  const {
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      errors,
      isSubmitting
    } = useLoserValidation({ ...formData.loser, preferredLanguage: locale}, validateLoser, locale);

  return (
    <form onSubmit={handleSubmit} className={classes.formControl}>
      <Typography component="h1" variant="h4" className={classes.title}>
        {I18n.getTranslation(window.location, 'LOSS_RECORD_LOSER_DATA')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <FormControl 
            variant="outlined" 
            required
            className={classes.formControl}
            >
            <TextField
              select
              required
              name="salutation" 
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_SALUTATION')}
              value={values.salutation || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="none"
              variant="outlined"
            >
              <option value="" />
              <option value="female">{I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_SALUTATION_FEMALE')}</option>
              <option value="male">{I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_SALUTATION_MALE')}</option>                                 
            </TextField>
          </FormControl>              
        </Grid>
		<Grid item sm={6} xs={12}>
		  <FormControl className={classes.formControl}>
		    <TextField
		      required
		      value={values.firstName || ''}
		      name="firstName"
		      label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_FIRSTNAME')}
		      fullWidth
		      onChange={handleChange}
              onBlur={handleBlur}
		      margin="none"
		      variant="outlined"     
		    />
		  </FormControl>
		</Grid>
		<Grid item sm={6} xs={12}>
		  <FormControl className={classes.formControl}>
		    <TextField
		      required
		      value={values.lastName || ''}
		      name="lastName"
		      label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_LASTNAME')}
		      fullWidth
			  onChange={handleChange}
              onBlur={handleBlur}
		      margin="none"
		      variant="outlined"     
		    />
		  </FormControl>
		</Grid>    
        <Grid item sm={8} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.address.street || ''}
              name="street"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_STREET')}
              fullWidth
              onChange={(e) => {handleChange(e, 'address')}}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"
            />
          </FormControl>
        </Grid>	
        <Grid item sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.address.streetNumber || ''}
              name="streetNumber"
              type="number"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_STREETNUMBER')}
              fullWidth
              onChange={(e) => {handleChange(e, 'address')}}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"
            />
          </FormControl>
        </Grid> 
        <Grid item sm={8} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.address.city || ''}
              name="city"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_CITY')}
              fullWidth
              onChange={(e) => {handleChange(e, 'address')}}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"    
            />
          </FormControl>
        </Grid>                 	    
        <Grid item sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.address.zipCode || ''}
              name="zipCode"
              type="text"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_ZIPCODE')}
              fullWidth
              onChange={(e) => {handleChange(e, 'address')}}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"
              error={errors.zipCode}
              helperText={errors.zipCode ? I18n.getTranslation(window.location, 'VAL_REQUIRED_ZIPCODE') : ''} 
            />
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.mobileNumber || ''}
              name="mobileNumber"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_MOBILE')}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"
              error={errors.mobileNumber}
              helperText={errors.mobileNumber ? I18n.getTranslation(window.location, 'VAL_MOBILE_NUMBER') : ''}
            />
          </FormControl>
        </Grid>            
        <Grid item sm={6} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              value={values.mailAddress || ''}
              type="email"
              name="mailAddress"
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_MAILADDRESS')}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"      
            />
          </FormControl>
        </Grid>   
        <Grid item sm={6} xs={12}>
          <FormControl 
            variant="outlined" 
            required
            className={classes.formControl}
            >
            <TextField
              select
              required
              name="countryId" 
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_COUNTRY')}
              value={values.address.countryId || ''}
              onChange={(e) => {handleChange(e, 'address')}}
              onBlur={handleBlur}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="none"
              variant="outlined"
            >
              <option value="" />
                {
                  countries
                  .sort((a, b) => ["priority"].indexOf(b.order) - ["priority"].indexOf(a.order))
                  .map((values,index) => (
                    <option key={index} value={values.id}>{values.name}</option>
                  )
                )};                                
            </TextField>
          </FormControl>                       
        </Grid>
        <Grid item sm={6} xs={12}>  
          <FormControl 
            variant="outlined" 
            required
            className={classes.formControl}
            >
            <TextField
              select
              required
              name="preferredCommunicationType" 
              label={I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_COMMUNICATION_TYPE')}
              value={values.preferredCommunicationType || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="none"
              variant="outlined"
            >
              <option value="" />
              <option value="email">{I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_COMMUNICATION_TYPE_EMAIL')}</option>
              <option value="sms">{I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_COMMUNICATION_TYPE_SMS')}</option>
              <option value="letter">{I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_COMMUNICATION_TYPE_LETTER')}</option>                               
            </TextField>
          </FormControl>                   
        </Grid>                                         
      </Grid>
      <div className={classes.buttons}>
        <Button onClick={() => {history.goBack()}} className={classes.button}>
          {I18n.getTranslation(window.location, 'GLOBAL_BACK')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={isSubmitting}
        >
          {I18n.getTranslation(window.location, 'GLOBAL_NEXT')}
        </Button>
      </div>      
    </form>
  );
}