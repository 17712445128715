import React, {useState, useContext} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from '../img/lostandfound.svg';
import { ReactComponent as LogoBlk } from '../img/lostandfound_blk.svg';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import TrainIcon from '@material-ui/icons/Train';
import FlightIcon from '@material-ui/icons/Flight';
import ListIcon from '@material-ui/icons/List';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

// Translations v2
import I18n from "../I18n";
import { NavLink, Link } from '../lib';
import history from '../lib/history';
import { FormContext } from "../context/FormContext";

//test
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

const stripLocale = (pathname, locale) => {
  if (!locale) {
    return pathname;
  }

  return pathname.replace(`/${locale}`, '');
};

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

// styles
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	drawer: {
		background: '#e5e5e5',
	},
	links: {
		color: '#444',
		textTransform: 'uppercase',
		fontWeight: '600',
		textDecoration: 'none'
	},
	menuButton: {
		marginRight: theme.spacing(2),
		float: 'right',
	},  
	title: {
		flexGrow: 1,
	},
	list: {
		width: 280,
	},
	fullList: {
		width: 'auto',
	},
	languageButton: {
		padding: 0
	},
	logo: {
		flex: 1,
		paddingTop: 7
	},
	logoblk: {
		flex: 1,
		paddingTop: 7,
		marginLeft: theme.spacing(8),
		marginTop: theme.spacing(4)
	},
	footer: {
		position: 'fixed',
		bottom: "0",
	},
	dense: {
		minHeight: 'auto',
	}
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ButtonAppBar = ({ location: { pathname }, match: { params: { locale } } }) => {
	const classes = useStyles();
	const [formData, setFormData] = useContext(FormContext);
	const [state, setState] = useState({
	    open: false,
	  });

	const toggleDrawer = (open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		  return;
		}
		setState({ ...state, open: open });
	};

	const handleChange = event => {
		const {value} = event.target
		setFormData({...formData, loser:{...formData.loser, preferredLanguage: value}})
		//push value from select and keep the route
	    history.push('/' + value + stripLocale(pathname, locale));
	};

	const MenuItems = () => (
		<div
		  className={classes.list}
		  role="presentation"
		  onClick={toggleDrawer(false)}
		  onKeyDown={toggleDrawer(false)}
		>
		<LogoBlk width="125" alt={I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')} className={classes.logoblk}/>
		<MenuList>
	        <MenuItem component={ForwardNavLink} to={'/'}>
	          <ListItemIcon>
	            <HomeIcon />
	          </ListItemIcon>
	          <Typography variant="inherit" className={classes.links} >{I18n.getTranslation(window.location, 'HOME_TITLE')}</Typography>
	        </MenuItem>
			<MenuItem component={ForwardNavLink} to={'/browse-claims'}>
	          <ListItemIcon>
	            <ListIcon />
	          </ListItemIcon>
	          <Typography variant="inherit" className={classes.links}>{I18n.getTranslation(window.location, 'BROWSE_CLAIMS')}</Typography>
	        </MenuItem>
	        <MenuItem component={ForwardNavLink} to={'/stations'}>
	          <ListItemIcon>
	            <TrainIcon />
	          </ListItemIcon>
	          <Typography variant="inherit" className={classes.links}>
	            {I18n.getTranslation(window.location, 'LOCATION_STATIONS')}
	          </Typography>
	        </MenuItem>
	        <MenuItem component={ForwardNavLink} to={'/airports'}>
	          <ListItemIcon>
	            <FlightIcon />
	          </ListItemIcon>
	          <Typography variant="inherit" className={classes.links}>
	            {I18n.getTranslation(window.location, 'LOCATION_AIRPORTS')}
	          </Typography>
	        </MenuItem>	        
	     </MenuList>	
			<MenuList className={classes.footer}>
		        <MenuItem className={classes.dense} component={ForwardNavLink} to={'/terms-and-conditions'}>
		          <Typography variant="body2">{I18n.getTranslation(window.location, 'TERMS_AND_CONDITIONS')}</Typography>
		        </MenuItem>
				<MenuItem className={classes.dense} component={ForwardNavLink} to={'/privacy-notice'}>
		          <Typography variant="body2">{I18n.getTranslation(window.location, 'PRIVACY_NOTICE')}</Typography>
		        </MenuItem>     
		     </MenuList>
		</div>
	);

	return (
		<div className={classes.root}>
		  <AppBar position="static" color="secondary">
		    <Toolbar>
		    <Grid
		      alignItems="center"
		      container
		    >
				<Grid item>
					<IconButton edge="start" className={classes.menuButton} onClick={toggleDrawer(true)} color="inherit" aria-label="Menu">
						<MenuIcon />
					</IconButton>
					<Drawer open={state.open} onClose={toggleDrawer(false)} classes={{ paper: classes.drawer }}>
						<MenuItems />					
					</Drawer>
				</Grid>

				<Grid item className={classes.logo}>
					<Link to="/">
						<Logo width="100" alt={I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}/>
					</Link>
				</Grid>

				<Grid item>
			      <FormControl className={classes.margin}>
			        <NativeSelect
			          value={locale}
			          onChange={handleChange}
			          input={<BootstrapInput name="lang" id="lang-select" />}
			        >
			          <option value="de" >{I18n.getTranslation(window.location, 'german')}</option>
			          <option value="fr" >{I18n.getTranslation(window.location, 'french')}</option>
			          <option value="en" >{I18n.getTranslation(window.location, 'english')}</option>
			        </NativeSelect>
			      </FormControl>	
				</Grid>
		    </Grid>			
  		    </Toolbar>
		  </AppBar>
		</div>
	);
}

export default ButtonAppBar;