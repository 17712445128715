import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Helmet} from "react-helmet-async";

// translations
import I18n from '../I18n';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  list: {
    padding: 0
  }, 
  catName: {
    fontWeight: 800,
    color: 'red'
  },
  title: {
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase'
  },
  table: {
    minWidth: 650,
  },  
}));

const Stations = ({match, location}) => {

	if(!match.params.stationId) {
		return(
			<div>
		        <Helmet>
		            <title> {I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_STATION')} | {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}</title>
		        </Helmet>
				<StationsList match={match}/>    
			</div>
		)
	} else {
		return(
			<div>	
				<StationDetails match={match} location={location}/>
			</div>
		)
	}
}

const StationDetails = (props) => {
	const classes = useStyles();
	const stations = require('../data/en/stations.json');
	const [stationData, setStationData] = useState([]);

	useEffect(() => (
		stations.map((values, index) => {
				if(values.id === parseInt(props.match.params.stationId)) {
					setStationData(values)
				}
			}
		)
	), []);

	return (
		<div>
        <Helmet>
            <title>{stationData.name || 'Station'} | {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}</title>
        </Helmet>			
          <Typography className={classes.title} component="h1" variant="h4" align="center">
           	{stationData.name}
          </Typography>
	     <Table className={classes.table}>
	        <TableBody>
	            <TableRow>
	              <TableCell component="th" scope="row">
	                {I18n.getTranslation(window.location, 'LOSS_RECORD_ADDRESS_STREET')}
	              </TableCell>
	              <TableCell align="right">{stationData.address}</TableCell>
	            </TableRow>	      
	            <TableRow>
	              <TableCell component="th" scope="row">
	                Website
	              </TableCell>
	              <TableCell align="right">{stationData.website}</TableCell>
	            </TableRow>	 	   
	            <TableRow>
	              <TableCell component="th" scope="row">
	                {I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_PHONE')}
	              </TableCell>
	              <TableCell align="right">{stationData.phoneNumber}</TableCell>
	            </TableRow>	 	  
	            <TableRow>
	              <TableCell component="th" scope="row">
	                {I18n.getTranslation(window.location, 'LOSS_RECORD_CONTACT_COMMUNICATION_TYPE_EMAIL')}
	              </TableCell>
	              <TableCell align="right">{stationData.emailAddress}</TableCell>
	            </TableRow>	 	                                     
	        </TableBody>
	      </Table>          
		</div>
	)
}

const StationsList = (props) => {
	const classes = useStyles();
  	const [stations, setStations] = useState([]);
  	const [filteredStations, setFilteredStations] = useState([]);	

	function urlString(name) {
		var one = name.toLowerCase();
		var two = one.replace("/", "-");
		var three = two.replace(/ /g,"-");;
		return encodeURI(three);
	}

	useEffect(() => {
	    // Update the document title using the browser API
	    const json = require('../data/en/stations.json');
	    setStations(json);
	    setFilteredStations(json);
	}, []);

	const handleSearch = (e) => {

	    var updatedList = stations;
	    updatedList = updatedList.filter(function(item){
	      return item.name.toLowerCase().search(
	        e.target.value.toLowerCase()) !== -1;
	    });
	    setFilteredStations(updatedList);

	}	

	return(
		<div>
			<Typography component="h1" variant="h4">
				{I18n.getTranslation(window.location, 'LOSS_RECORD_LOSS_HANDOVER_STATION')}
			</Typography>
			<form className={classes.container} noValidate autoComplete="off">
				<TextField
				  id="outlined-name"
				  label={I18n.getTranslation(window.location, 'LOSS_RECORD_LOOKING_FOR')}
				  className={classes.textField}
				  placeholder={I18n.getTranslation(window.location, 'LOSS_RECORD_CATEGORY_SEARCH_PLACEHOLDER')}
				  onChange={(e) => {handleSearch(e)}}
				  fullWidth
				  margin="normal"
				  variant="outlined"
				/>
			</form>			
			<div>
			    {filteredStations
			      // .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			      .map((values,index) => (
			      	<div key={index}>
			          <ListItem 
			          	button
			          	{...{ to: { pathname: `${props.match.url}/${values.id}/${urlString(values.name)}`/*, state: { station: values.name, stationId: values.id, index:index}*/ } }}
			          	component={Link}
			          >
			            <ListItemText primary={values.name} />
			            <ExpandMore />
			          </ListItem>   		
			        </div>
			      )
			    )}
			</div>
		</div>
	)		
}

export default withRouter(Stations);