import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import I18n from '../I18n';
import { getPost } from "../helpers/apiCalls";

const terms = {
	"en": 5,
	"fr": 10,
	"de": 12
}

const Terms = ({ location: { pathname }, match: { params: { locale } } }) => {
	const [content, setContent] = useState([])
	const [modified, setModified] = useState('')

	useEffect(() => {
		getPost(terms[locale]).then(function(response) {
			setContent(response.content.rendered)
			setModified(response.modified)
		})
	}, [locale]);   

	return (
		<div>
			<Typography component="h1" variant="h4">
				{I18n.getTranslation(window.location, 'TERMS_AND_CONDITIONS')}   
			</Typography>
			<p dangerouslySetInnerHTML={{__html: content}} />
			<Typography variant="caption">
				{I18n.getTranslation(window.location, 'LAST_UPDATE')}: {modified}
			</Typography>			
		</div>
	)
  
}

export default Terms