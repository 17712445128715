import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
// import {Helmet} from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// detect language
import detectBrowserLanguage from 'detect-browser-language'

// Custom imports
import Layout from './layouts/Main';
import Home from "./pages/stepHome";
import Terms from "./pages/terms";
import PrivacyNotice from "./pages/privacy-notice";
import CookieNotice from "./pages/cookie-notice";
import Browse from "./pages/browseClaims";
import Stations from "./pages/stations";
import AirportDetails from "./pages/airports/airportDetails";
import AirportsList from "./pages/airports/airportsList";
import ButtonAppBar from './layouts/ButtonAppBar';
// steps
import stepLostProperty from "./pages/stepLostProperty";
import stepLoser from "./pages/stepLoser";
import stepLocation from "./pages/stepLocation";
import stepOverview from "./pages/stepOverview";
import stepSuccess from "./pages/stepSuccess";
import stepCancel from "./pages/stepCancel";
//provider
import { FormProvider } from './context/FormContext';

import Theme from './Theme';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import history from './lib/history';
import I18n from './I18n';

const base = '/:locale(en|fr|de)?';
const lang = detectBrowserLanguage(); // do the substr because on ios the lang is fr-CH, which is not setup and brakes the app

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
    	<HelmetProvider>
	    	<FormProvider>
		        <Helmet>
		            <title> Fundservice Schweiz | {I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}</title>
		            <meta name="description" content={`${I18n.getTranslation(window.location, 'SEO_TITLE')} ${I18n.getTranslation(window.location, 'GLOBAL_APP_TITLE')}`}/>
					<link rel="alternate" href={`${window.location.host}/en`} hreflang="en" />
					<link rel="alternate" href={`${window.location.host}/fr`} hreflang="fr-ch" />
					<link rel="alternate" href={`${window.location.host}/de`} hreflang="de-ch" />
		        </Helmet>
			    <CssBaseline />
			    <Router history={history} basename={process.env.PUBLIC_URL}>
			      <Route path={base} component={ButtonAppBar} />
			      <Layout path={base} >
			      	<Switch>
			            <Redirect exact from='/' to={lang.substr(0,2)}/>
				        <Route exact path={base} component={Home} />
				        
					        <Route path={`${base}/report/lost-property`} component={stepLostProperty} />
					        <Route path={`${base}/report/location`} component={stepLocation} />
					        <Route strict exact path={`${base}/report/loser`} component={stepLoser} />
					        <Route strict exact path={`${base}/report/overview`} component={stepOverview} />
					        <Route path={`${base}/report/success/:reportId`} component={stepSuccess} />
					        <Route path={`${base}/report/cancel`} component={stepCancel} />
					        
				        <Route strict exact path={`${base}/browse-claims`} component={Browse} />
				        <Route strict exact path={`${base}/terms-and-conditions`} component={Terms} />
				        <Route strict exact path={`${base}/privacy-notice`} component={PrivacyNotice} />
				        <Route strict exact path={`${base}/cookie-notice`} component={CookieNotice} />
				        <Route strict exact path={`${base}/stations`} component={Stations} />
				        <Route exact path={`${base}/stations/:stationId/:stationName`} component={Stations} />
				        <Route strict exact path={`${base}/airports`} component={AirportsList} />
				        <Route exact path={`${base}/airports/:slug`} component={AirportDetails} />
				        {<Route render={() => <Redirect to="/" />} />}
			        </Switch>
			      </Layout>
			    </Router>
		    </FormProvider>
		</HelmetProvider>
    </ThemeProvider>    
  );
}

export default App;
