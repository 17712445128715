// import React, {useState, useEffect} from 'react';
// import firebase from "../Firestore";

//consts
// const db = firebase.firestore();

// used in Overview
export async function lossrecordsCall (formData) {

	let response = await fetch('https://cors-anywhere.herokuapp.com/https://lostandfound.sbb.ch/home/sbb/SBB/api/'+formData.loser.preferredLanguage+'/lossrecords', {
	  method: 'POST',
	  headers: {
	    'Accept': 'application/json',
	    'Content-Type': 'application/json',
	    'X-Requested-With': 'xhr',
	  },
	  body: JSON.stringify(formData)
	})

	return await response.json()	
}

// used in Success Page
export async function possiblematchesCall (formData) {

	let response = await fetch('https://cors-anywhere.herokuapp.com/https://lostandfound.sbb.ch/home/sbb/SBB/api/'+formData.loser.preferredLanguage+'/possiblematches', {
	  method: 'POST',
	  headers: {
	    'Accept': 'application/json',
	    'Content-Type': 'application/json',
	    'X-Requested-With': 'xhr',
	  },
	  body: JSON.stringify(formData)
	})
	
	return await response.json()

}

// get posts
export async function getPost (postId) {

	let response = await fetch('https://data.lostfound.services/wp-json/wp/v2/posts/'+postId)
	return await response.json();
}
