import {useState, useContext} from "react";
import { FormContext } from "../context/FormContext";
import history from '../lib/history';

function useLostpropertyValidation(initialState, locale) {
  const [properties, setProperties] = useState(initialState);
  const [formData, setFormData] = useContext(FormContext);

  function handleChange(event, index) {
    var {value, name} = event.target;
    event.persist();

    // custom form assignment
    if(name === 'boolean') {
    	value = Boolean(value) // turn 0/1 in true/false
    } 

  	setProperties({
  		...properties, 
  		[index]: {
    			value // assign the new value
    	} });
  }

  // handleColor because it comes differently from react-color
  async function handleColor(event, index) {
    setProperties(properties => ({...properties, [index]: { value: event.hex }}));    
  }   

  // handleCurrency because it comes differently from form
  async function handleCurrency(event, index) {
    var {value} = event.target;
    value = parseInt(value)
    setProperties(properties => ({...properties, [index]: {value: (Number.isInteger(value)) ? value : 0, currency: 'CHF'} }));    
  }   

  // async function handleBlur() {
  //   const validationErrors = await validate(properties);
  //   // console.log(validationErrors)
  //   setErrors(validationErrors);
  // }

  async function handleSubmit(event) {
    event.preventDefault();

    setFormData({
      ...formData,
      lossItems: [{
        ...formData["lossItems"][0],
        properties
      }]
    })

    history.push('/'+locale+'/report/location')

  }

  return {
    handleSubmit,
    handleChange,
    handleColor,
    handleCurrency,
    properties,
    setProperties
  };
}

export default useLostpropertyValidation;
