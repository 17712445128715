import React from 'react';
// import Loading from '../img/loading.gif';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default () => (
  <div>
	<Grid container justify="center" alignItems="center">
	  <CircularProgress />
	</Grid>
  </div>
)