import React, {useState, createContext, useEffect} from 'react';

export const FormContext = createContext();

let current_datetime = new Date();
current_datetime.setHours(0);

export const FormProvider = (props) => {
  const [formData, setFormData] = useState({
    airport: {},
    dateOfLoss: current_datetime.toISOString(),
    // lossLocation: '',
    handoverLocation: {
      type: 'address',
      // stationId: 0
    },
    comment: null,
    loser: {
      address: {}
    },
    settings: {
      catName: {},
      subcatName: {},
    }
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(formData);
    } 
  }, [formData]);

  return (
    <FormContext.Provider value={[formData, setFormData]}>
      {props.children}
    </FormContext.Provider>
  )
}
