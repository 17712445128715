import { createI18n } from 'react-router-i18n';
import EnTrans from './data/en/translations.json';
import DeTrans from './data/de/translations.json';
import FrTrans from './data/fr/translations.json';
// The first in the array is treated as the default locale
const locales = ['en', 'fr', 'de'];
 
// Dictionary of translations
const translations = {
  en: { ...EnTrans,
    SUBTITLE: 'Report a loss',
    SEO_TITLE: 'Lost and Found',
    SIDEBAR_TITLE: 'Lost reports',
    ABOUT_TITLE: 'About',
    HOME_TITLE: 'Home',
    HELP: 'Help',
    EMPTY_LOSS_ITEMS: 'Lost Items should not be empty',
    EMPTY_LOSER: 'You must add your personal details',
    LOADING: 'Loading...',
    MISSING_PERSONAL_DATA: 'Personal data is missing. Please go back and insert your personal details',
    MISSING_LOSSITEMS: 'Your lost item is missing. Please go back and select an item from the list.',
    TOTAL: 'Total',
    BROWSE_CLAIMS: 'Browse claims',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    TERMS_AND_CONDITIONS_ACCEPT: 'Please accept the terms and conditions.',
    PRIVACY_NOTICE: 'Privacy Notice',
    COOKIE_NOTICE: 'Cookie Notice',
    COOKIE_NOTICE_TEXT: 'I agree that this Website uses cookies and similar technologies for providing me this Website and its functionalities and for obtaining insights about its use. To find out more about cookies and how to manage them, please see our ',
    COOKIE_NOTICE_BUTTON: 'Close',
    LAST_UPDATE: 'Last update',
    AGREE_TERMS_1: 'I agree with the ',
    AGREE_TERMS_2: 'terms and conditions ',
    AGREE_TERMS_3: 'and the ',
    AGREE_TERMS_4: 'privacy policy',
    LOST_PROPERTY_REPORT: 'Lost Property Report',
    PAYMENT_FEE: 'Payment Fee',
    PAYMENT_CANCEL: 'There was a problem while processing your payment and therefore your Report was not saved. This error was logged. Please try again later.',
    language: 'Language',    
    french: 'Français',
    english: 'English',
    german: 'Deutsch',
    ABOUT_PAGE_TITLE: 'about',
    PAYMENT_DETAILS: 'Payment details',
    PAYMENT_ERROR: 'There was an error recording your loss',
    HOME_TEXT: 'Have you lost something on the train, station or airport? We may well find it again quickly or an honest finder may leave it with us. Report it immediately online and we’ll contact you as soon as we’ve found your lost property.',
    AIRPORTS_HOME_TEXT: 'Have you lost something at the airport? We may well find it again quickly or an honest finder may leave it in the Lost and Found services of the airport or respective handler. Report it immediately online and the services will contact you as soon as they found your lost property.',
    LOSS_RECORD_LOSS_TRAIN: 'Train / Station',
    LOSS_RECORD_LOSS_AIRPORT: 'Airport',
    LOSS_RECORD_LOSS_PLANE: 'Plane',
    LOSS_RECORD_LOSS_BUS: 'Bus',
    LOSS_RECORD_LOSS_STREET: 'Street',
    LOSS_RECORD_LOSS_UNKNOWN: "I don't know",
    LOSS_RECORD_LOSS_OTHER: "Other",
    LOSS_RECORD_AT: "Lost something at",
    LOSS_RECORD_THANK_YOU: "Your entered loss report has been saved and will be included in further found reports. You're going to receive your reference number by email in a few minutes.",
    CREATE_LOSS_REPORT: "Create new loss report",
    LOCATION_WEBSITE: "Website",
    AIRPORT_CODE: "Airport Code",
    LOCATION_STATIONS: 'Stations',
    LOCATION_AIRPORTS: 'Airports',
    LOCATION_AIRLINE: 'Airline',
    LOCATION_FLIGHT_NUMBER: 'Flight Number',
    LOCATION_FLIGHT_DATE: 'Flight Date',
    LOSS_ITEM_DESCRIPTION: 'Description',
    LOSS_ITEM_MAIN_CATEGORY: 'Main Category',
    LOSS_ITEM_SUB_CATEGORY: 'Sub-category',
    LOSS_ITEM_SUB_LOCATION: 'Place',
  },
  fr: { ...FrTrans,
    SUBTITLE: 'Créer un avis de perte / ordre de recherche',
    SEO_TITLE: 'Objets trouvés',
    SIDEBAR_TITLE: 'Ordres de recherche',
    ABOUT_TITLE: 'A propos',
    HOME_TITLE: 'Accueil',
    HELP: 'Aide',
    EMPTY_LOSS_ITEMS: 'Vous devrez ajouter un objet perdu',
    EMPTY_LOSER: 'Vous devrez ajouter vos données personnelles',
    LOADING: 'Loading...',
    MISSING_PERSONAL_DATA: 'Personal data is missing. Please go back and insert your personal details',
    MISSING_LOSSITEMS: 'Your lost item is missing. Please go back and select an item from the list.',
    TOTAL: 'Totale',
    BROWSE_CLAIMS: 'Voir déclarations',
    TERMS_AND_CONDITIONS: 'Conditions d’utilisation',
    TERMS_AND_CONDITIONS_ACCEPT: 'Svp acceptez les conditions avant avancer.',
    PRIVACY_NOTICE: 'Protection des données',
    COOKIE_NOTICE: 'Cookie Notice',
    COOKIE_NOTICE_TEXT: "J’accepte que ce site web utilise des cookies et d’autres technologies similaires permettant de vous proposer ce site web et ses fonctionnalités et d’obtenir des informations quant à son utilisation. Pour en savoir plus à propos des cookies, veuillez-vous référer à notre ",
    COOKIE_NOTICE_BUTTON: 'Fermer',
    LAST_UPDATE: 'Last update',
    AGREE_TERMS_1: 'Je confirme accepter les ',
    AGREE_TERMS_2: "conditions d'utilisation ",
    AGREE_TERMS_3: 'et la ',
    AGREE_TERMS_4: 'Cookie Notice',    
    LOST_PROPERTY_REPORT: 'Déclaration de perte',
    PAYMENT_FEE: 'Frais de traitement',
    PAYMENT_CANCEL: 'There was a problem while processing your payment and therefore your Report was not saved. This error was logged. Please try again later.',
    language: 'Langue',    
    french: 'Français',
    english: 'English',
    german: 'Deutsch',
    ABOUT_PAGE_TITLE: 'a-propos',
    PAYMENT_DETAILS: 'Informations de paiement',
    PAYMENT_ERROR: 'Il y a un problème.',
    HOME_TEXT: 'Vous avez égaré un objet dans le train, à la gare ou aéroport? Il est possible que nous le retrouvions rapidement ou qu’il soit rapporté par une personne honnête. Déposez sans tarder un avis de perte en ligne et nous vous tiendrons au courant de l’avancée de nos recherches.',
    AIRPORTS_HOME_TEXT: "Vous avez perdu un objet à l'aéroport? Il est possible que les services le trouve rapidement ou qu’il soit rapporté par une personne honnête. Déposez sans tarder un avis de perte en ligne et vous aller être informé de l’avancée de nos recherches.",
    LOSS_RECORD_LOSS_TRAIN: 'Train / Gare',
    LOSS_RECORD_LOSS_AIRPORT: 'Aéroport',
    LOSS_RECORD_LOSS_PLANE: 'Avion',
    LOSS_RECORD_LOSS_BUS: 'Bus',
    LOSS_RECORD_LOSS_STREET: 'Rue',
    LOSS_RECORD_LOSS_UNKNOWN: "Je ne sais pas",    
    LOSS_RECORD_LOSS_OTHER: "Autre",
    LOSS_RECORD_AT: "Avez-vous perdu quelque chose à",
    LOSS_RECORD_THANK_YOU: "La déclaration de la perte enregistrée par vous est stockée et  sera pris en compte dans les futurs rapports de la découverte. Vous allez recevoir votre numéro de perte par email dans quelques minutes.",
    CREATE_LOSS_REPORT: "Créer déclaration de perte",
    LOCATION_WEBSITE: "Site web",
    AIRPORT_CODE: "Code Aéroport",
    LOCATION_STATIONS: 'Gares',
    LOCATION_AIRPORTS: 'Aéroports',
    LOCATION_AIRLINE: 'Compagnie aérienne',
    LOCATION_FLIGHT_NUMBER: 'Numéro de vol',
    LOCATION_FLIGHT_DATE: 'Date du vol',    
    LOSS_ITEM_DESCRIPTION: 'Déscription',
    LOSS_ITEM_MAIN_CATEGORY: 'Catégorie',
    LOSS_ITEM_SUB_CATEGORY: 'Sous-catégorie',
    LOSS_ITEM_SUB_LOCATION: 'Lieu'
  },
  de: {...DeTrans,
    SUBTITLE: 'Verlustmeldung / Suchauftrag',
    SEO_TITLE: 'Fundservice',
    SIDEBAR_TITLE: 'Suchauftrag',
    ABOUT_TITLE: 'Uber uns',
    HOME_TITLE: 'Startseite',
    HELP: 'Hilfe',
    EMPTY_LOSS_ITEMS: 'Verlorene Gegenstände darf nicht leer sein.',
    EMPTY_LOSER: 'Personendaten darf nicht leer sein.',
    LOADING: 'Loading...',
    MISSING_PERSONAL_DATA: 'Personal data is missing. Please go back and insert your personal details',
    MISSING_LOSSITEMS: 'Your lost item is missing. Please go back and select an item from the list.',
    TOTAL: 'Gesamtmenge',
    BROWSE_CLAIMS: 'Verlustmeldungen',
    TERMS_AND_CONDITIONS: 'Nutzungsbedingungen',
    TERMS_AND_CONDITIONS_ACCEPT: 'Bitte akzeptieren Sie die Bedingungen.',
    PRIVACY_NOTICE: 'Datenschutz',
    COOKIE_NOTICE: 'Cookie Notice',
    COOKIE_NOTICE_TEXT: 'Ich bin damit einverstanden, dass diese Webseite Cookies und ähnliche Technologien verwendet, um mir diese Website und ihre Funktionalitäten zur Verfügung zu stellen und um Erkenntnisse über die Nutzung zu gewinnen. Um mehr über Cookies zu erfahren, lesen Sie bitte auch unseren ',
    COOKIE_NOTICE_BUTTON: 'Schliessen',
    LAST_UPDATE: 'Last update',
    AGREE_TERMS_1: 'Ich bestätige die ',
    AGREE_TERMS_2: "AGB ",
    AGREE_TERMS_3: 'un das ',
    AGREE_TERMS_4: 'Cookie Notice ',     
    AGREE_TERMS_5: 'akzeptieren.',     
    LOST_PROPERTY_REPORT: 'Verlustmeldung',
    PAYMENT_FEE: 'Bearbeitungsgebühr',
    PAYMENT_CANCEL: 'There was a problem while processing your payment and therefore your Report was not saved. This error was logged. Please try again later.',
    language: 'Sprache',    
    french: 'Français',
    english: 'English',
    german: 'Deutsch',    
    ABOUT_PAGE_TITLE: 'uber-uns',
    PAYMENT_DETAILS: 'Zahlung',
    PAYMENT_ERROR: 'Es ist ein problem!',
    HOME_TEXT: 'Sie haben im Zug oder am Bahnhof einen Gegenstand verloren? Gut möglich, dass wir ihn rasch wiederfinden oder ein ehrlicher Finder ihn bei uns abgibt. Geben Sie gleich online eine Verlustmeldung auf und wir benachrichtigen Sie, sobald wir Ihr Hab und Gut ausfindig machen.',
    AIRPORTS_HOME_TEXT: 'Sie haben am Flughafen einen Gegenstand verloren? Gut möglich, dass wir ihn rasch wiederfinden oder ein ehrlicher Finder ihn bei uns abgibt. Geben Sie gleich online eine Verlustmeldung.',
    LOSS_RECORD_LOSS_TRAIN: 'Zug / Bahnhof',
    LOSS_RECORD_LOSS_AIRPORT: 'Flughafen',
    LOSS_RECORD_LOSS_PLANE: 'Flugzeug',
    LOSS_RECORD_LOSS_BUS: 'Bus',
    LOSS_RECORD_LOSS_STREET: 'Strasse',
    LOSS_RECORD_LOSS_UNKNOWN: "Ich weiss es nicht",    
    LOSS_RECORD_LOSS_OTHER: "Andere",
    LOSS_RECORD_AT: "Haben Sie etwas verloren am",
    LOSS_RECORD_THANK_YOU: "Die Berichterstattung über den Verlust von Ihnen aufgezeichnet wird gespeichert und wird in künftigen Berichten über die Entdeckung in Betracht gezogen werden. Sie erhalten Ihre Referenznummer in wenigen Minuten per E-Mail.",
    CREATE_LOSS_REPORT: "Neue Verlustmeldung",
    LOCATION_WEBSITE: "Website",
    AIRPORT_CODE: "Flughafen Code",
    LOCATION_STATIONS: 'Bahnhof',
    LOCATION_AIRPORTS: 'Flughafen',
    LOCATION_AIRLINE: 'Airline',
    LOCATION_FLIGHT_NUMBER: 'Flugnummer',
    LOCATION_FLIGHT_DATE: 'Flugdatum',    
    LOSS_ITEM_DESCRIPTION: 'Beschreibung',    
    LOSS_ITEM_MAIN_CATEGORY: 'Hauptkategorie',
    LOSS_ITEM_SUB_CATEGORY: 'Unterkategorie',
    LOSS_ITEM_SUB_LOCATION: 'Ort'   
  }  
}
 
const I18n = createI18n(
  locales,
  translations,
);
 
export default I18n;