import React from 'react';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';

//translations
import I18n from '../I18n';

/*
const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: 14,
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));
*/

const Cancel = () => {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        {I18n.getTranslation(window.location, 'PAYMENT_ERROR')}
      </Typography>
      {I18n.getTranslation(window.location, 'PAYMENT_CANCEL')}
    </React.Fragment>
  );
}

export default Cancel;