import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import I18n from '../I18n';
import { getPost } from "../helpers/apiCalls";

const terms = {
	"en": 15,
	"fr": 17,
	"de": 19
}

const PrivacyNotice = ({ location: { pathname }, match: { params: { locale } } }) => {
	const [content, setContent] = useState([])

	useEffect(() => {
		getPost(terms[locale]).then(function(response) {
			setContent(response.content.rendered)
		})
	}, [locale]);   

	return (
		<div>
			<Typography component="h1" variant="h4">
				{I18n.getTranslation(window.location, 'PRIVACY_NOTICE')}     
			</Typography>
			<p dangerouslySetInnerHTML={{__html: content}} />
		</div>
	)
  
}

export default PrivacyNotice